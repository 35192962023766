<template>
  <div class="home">
    <div class="card1" v-if="customer==='jinlang'">
      <div class="title"><span></span>&nbsp;选择屋顶类型</div>
      <ul class="roorType">
        <li @click="goRoofItem('/flatRoof')">
          <span>平屋顶</span>
          <img src="./../assets/images/平屋顶.png" alt="" />
        </li>
        <li @click="goRoofItem('/slopingRoof')">
          <span>斜屋顶</span>
          <img src="./../assets/images/斜屋顶.png" alt="" />
        </li>
        <li @click="goRoofItem('/innerbuilding')">
          <span>内厦屋顶</span>
          <img src="./../assets/images/内厦屋顶.png" alt="" />
        </li>
        <li @click="goRoofItem('/outerbuilding')">
          <span>外厦带平台屋顶</span>
          <img src="./../assets/images/外厦带平台屋顶.png" alt="" />
        </li>
<!--        <li @click="goRoofItem('/compositeRoof')">-->
<!--          <span>复合屋顶</span>-->
<!--          <img src="./../assets/images/复合屋顶.png" alt="" />-->
<!--        </li>-->
      </ul>
    </div>
    <div class="card2" v-if="customer==='qingtian'">
      <div class="title">
        <img src="@/assets/images/qingtian/选择.png" alt="" />&nbsp;选择屋顶类型
      </div>
      <div class="roofBox">
        <ul class="roorType">
          <li class="item" @click="goRoofItem('/flatRoof')">
            <img src="@/assets/images/qingtian/平屋顶.png" alt="" />
          </li>
          <li>平屋顶</li>
        </ul>
        <ul class="roorType">
          <li class="item" @click="goRoofItem('/slopingRoof')">
            <img class="img" src="@/assets/images/qingtian/斜屋顶.png" alt="" />
          </li>
          <li>斜屋顶</li>
        </ul>
        <ul class="roorType">
          <li class="item" @click="goRoofItem('/innerbuilding')">
            <img class="img1" src="@/assets/images/qingtian/内厦.png" alt="" />
          </li>
          <li>内厦屋顶</li>
        </ul>
        <ul class="roorType">
          <li class="item" @click="goRoofItem('/outerbuilding')">
            <img class="img2" src="@/assets/images/qingtian/外厦.png" alt="" />
          </li>
          <li>外厦带平台屋顶</li>
        </ul>
<!--        <ul class="roorType">-->
<!--          <li class="item" @click="goRoofItem('/compositeRoof')">-->
<!--            <img class="img3" src="@/assets/images/qingtian/复合屋顶.png" alt="" />-->
<!--          </li>-->
<!--          <li>复合屋顶</li>-->
<!--        </ul>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import FarmerInfo from './../components/FarmerInfo'
import { Dialog, Toast } from 'vant'
import { ObliqueRoofInfo, FlatRoofInfo } from '@/api/api'
export default {
  components: {
    FarmerInfo
  },
  name: 'Home',
  setup() {
    const router = useRouter()
    const route = useRoute()

    const data = reactive({
      customer: window.customer
    })
    const addget = JSON.parse(sessionStorage.getItem('projectAddRequest'))
    console.log(addget)
    if (Object.keys(route.query).length) {
      sessionStorage.setItem('URLquery', JSON.stringify(route.query))
      for (const i in route.query) {
        if (!route.query[i]) {
          if (addget.BelongToCompany === '晴天') {
            Dialog.alert({
              message: "农户信息不全,请返回晴天云重试"
            }).then(() => {
              router.go(-1)
            })
          }
          if (addget.BelongToCompany === '锦浪') {
            Dialog.alert({
              message: "农户信息不全,请返回锦浪云重试"
            }).then(() => {
              router.go(-1)
            })
          }
        }
      }
    } else {
      if (addget.BelongToCompany === '晴天') {
        Dialog.alert({
          message: "未查询到农户信息,请返回晴天云重试"
        }).then(() => {
          router.go(-1)
        })
      }
      if (addget.BelongToCompany === '锦浪') {
        Dialog.alert({
          message: "未查询到农户信息,请返回锦浪云重试"
        }).then(() => {
          router.go(-1)
        })
      }
    }
    const methodsMap = {
      goRoofItem(path) {
        if (path === '/flatRoof') {
          sessionStorage.setItem('roofType', '平屋顶')
          FlatRoofInfo().then(res => {
            console.log(res.data.data, '平屋顶基础信息')
            if (res.data.data !== null) {
              var dataInfo = res.data.data
              sessionStorage.setItem('flatAgain', JSON.stringify(dataInfo))
              // var dataInfo = JSON.parse(sessionStorage.getItem('flatAgain'))
              if (dataInfo !== null && dataInfo.flatRoofBasicModel !== null) {
                sessionStorage.setItem('requestFlatRoof', JSON.stringify(dataInfo.flatRoofBasicModel))

                var infoData = dataInfo.flatRoofBasicModel
                if (infoData.InstallationMethod === 1) {
                  infoData.InstallationMethod = '混泥土基墩'
                } else if (infoData.InstallationMethod === 2) {
                  infoData.InstallationMethod = '膨胀螺栓'
                } else {
                  infoData.InstallationMethod = '混凝土+膨胀螺栓'
                }

                if (infoData.RoofingType === 1) {
                  infoData.RoofingType = '现浇板'
                } else if (infoData.RoofingType === 2) {
                  infoData.RoofingType = '预制板'
                } else if (infoData.RoofingType === 3) {
                  infoData.RoofingType = '木望板'
                } else {
                  infoData.RoofingType = '凛橼结构'
                }
                infoData.houseHeight = infoData.houseHeight / 10 / 100
                infoData.gridConnectionDistance = infoData.gridConnectionDistance / 10 / 100
                infoData.roofSlabThickness = infoData.roofSlabThickness / 10 / 100

                sessionStorage.setItem('baseInfo', JSON.stringify(infoData))
              }
            }
          })
        } else if (path === '/slopingRoof') {
          sessionStorage.setItem('roofType', '斜屋顶')
          // 获取斜屋顶的基本数据
          ObliqueRoofInfo().then((res) => {
            console.log(res, "斜屋顶的基本信息");
            if (res.data.data !== null) {
              var dataInfo1 = res.data.data;
              sessionStorage.setItem("slopingAgain", JSON.stringify(dataInfo1));
              if (dataInfo1 !== null && dataInfo1.obliqueRoofBasicModel !== null) {
                sessionStorage.setItem('requestObliqueRoof', JSON.stringify(dataInfo1.obliqueRoofBasicModel))
                var infoData = dataInfo1.obliqueRoofBasicModel
                infoData.southSlopeWidth = infoData.southSlopeWidth / 10 / 100
                infoData.southSlope = infoData.southSlope + 0
                infoData.northSlopeWidth = infoData.northSlopeWidth / 10 / 100
                infoData.northSlope = infoData.northSlope + 0
                infoData.eavesHigh = infoData.eavesHigh / 10 / 100
                infoData.topViewPic = infoData.topViewPic + 0
                infoData.sideViewPic = infoData.sideViewPic + 0
                infoData.rearViewPic = infoData.rearViewPic + 0
                infoData.frontViewPic = infoData.frontViewPic + 0
                infoData.houseHeight = infoData.houseHeight / 10 / 100
                if (infoData.houseFacing === 1) {
                  infoData.houseFacing = "正南"
                } else if (infoData.houseFacing === 2) {
                  infoData.houseFacing = "南偏东"
                } else if (infoData.houseFacing === 3) {
                  infoData.houseFacing = "南偏西"
                }
                infoData.facingDegree = infoData.facingDegree + 0
                infoData.gridConnectionDistance /= 1000
                infoData.ridgeHigh = infoData.ridgeHigh / 10 / 100
                if (infoData.roofingType === 1) {
                  infoData.roofingType = "现浇板"
                } else if (infoData.roofingType === 2) {
                  infoData.roofingType = "预制板"
                } else if (infoData.roofingType === 3) {
                  infoData.roofingType = "木望板"
                } else if (infoData.roofingType === 4) {
                  infoData.roofingType = "凛橼结构"
                }
                infoData.roofSlabThickness = infoData.roofSlabThickness / 10 / 100
                infoData.woodPurlinDiameter = infoData.woodPurlinDiameter / 10 / 100
                infoData.woodPurlinSpacing = infoData.woodPurlinSpacing / 10 / 100
                infoData.woodWatchboardThickness = infoData.woodWatchboardThickness / 10 / 100
                infoData.purlinDiameter = infoData.purlinDiameter / 10 / 100
                infoData.purlinSpacing = infoData.purlinSpacing / 10 / 100
                infoData.herringboneBeamEastWest = infoData.herringboneBeamEastWest / 10 / 100
                infoData.herringboneBeamNorthSouth = infoData.herringboneBeamNorthSouth / 10 / 100
                infoData.herringboneGirderDiameter = infoData.herringboneGirderDiameter / 10 / 100
                if (infoData.installationMethod === 1) {
                  infoData.installationMethod = "前支背拉"
                } else if (infoData.installationMethod === 2) {
                  infoData.installationMethod = "挂钩"
                }

                sessionStorage.setItem('baseInfo', JSON.stringify(infoData))
              }
            }
          });
        } else if (path === '/innerbuilding') {
          sessionStorage.setItem('roofType', '内厦屋顶')
          // 获取内厦屋顶的基本信息
          ObliqueRoofInfo().then((res) => {
            console.log(res.data.data, "内厦屋顶的基本信息");
            if (res.data.data !== null) {
              const dataInfo = res.data.data;
              if (dataInfo.obliqueRoofBasicModel !== null) {
                sessionStorage.setItem('inpValW', dataInfo.obliqueRoofBasicModel.eastWestLength)
                sessionStorage.setItem('inpValH1',dataInfo.obliqueRoofBasicModel.northSlopeWidth)
                sessionStorage.setItem('inpValH2', dataInfo.obliqueRoofBasicModel.southSlopeWidth)
              }
              sessionStorage.setItem("innerBuildingAgain", JSON.stringify(dataInfo));
              // if (res.data.data.obliqueRoofBasicModel !== null) {
              //   const dataInfo = res.data.data.obliqueRoofBasicModel;
              //   sessionStorage.setItem("innerBuildingAgain", JSON.stringify(dataInfo));
              // }
            }
          });
        } else if (path === '/outerbuilding') {
          sessionStorage.setItem('roofType', '外厦带平台屋顶')
          // 获取外厦带平台屋顶的基本信息
          ObliqueRoofInfo().then((res) => {
            console.log(res.data.data, "外厦带平台屋顶的基本信息");
            if (res.data.data !== null) {
              const dataInfo = res.data.data;
              if (dataInfo.obliqueRoofBasicModel !== null) {
                sessionStorage.setItem('inpValW', dataInfo.obliqueRoofBasicModel.eastWestLength)
                sessionStorage.setItem('inpValH1',dataInfo.obliqueRoofBasicModel.northSlopeWidth)
                sessionStorage.setItem('inpValH2', dataInfo.obliqueRoofBasicModel.southSlopeWidth)
                sessionStorage.setItem('inpValPw', dataInfo.obliqueRoofBasicModel.southSidePlatformWidth)
                sessionStorage.setItem('inpValPwl', dataInfo.obliqueRoofBasicModel.southSidePlatformLength)
                sessionStorage.setItem('inpValPwd', dataInfo.obliqueRoofBasicModel.southSidePlatformLocation)
              }
              sessionStorage.setItem("outerBuildingAgain", JSON.stringify(dataInfo));
              // if (res.data.data.obliqueRoofBasicModel !== null) {
              //   const dataInfo = res.data.data.obliqueRoofBasicModel;
              //   sessionStorage.setItem("innerBuildingAgain", JSON.stringify(dataInfo));
              // }
            }
          });
        }
        Toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        })
        setTimeout(() => {
          Toast.clear()
          router.push(path)
        }, 1000)
      }
    }
    // router.afterEach((to, from) => {
    //   // if (from.path === '/flatRoof' && to.path === '/home') {
    //   //   sessionStorage.clear()
    //   //   location.reload()
    //   // }
    //   if (to.path === '/home') {
    //     console.log('99999999999999')
    //     sessionStorage.clear()
    //     location.reload()
    //   }
    //   if (to.path === '/home' && from.path === '/slopingRoof') {
    //     sessionStorage.clear()
    //     location.reload()
    //   }
    // })
    onMounted(() => {
      setTimeout(() => {
        if (sessionStorage.getItem('FarmerInfo') === null) {
          Dialog.alert({
            message: "该项目无信息,请换项目尝试"
          }).then(() => {
          })
        }
      }, 1500)
    })

    return { ...toRefs(data), ...methodsMap, onMounted }
  },
  // beforeEnter: (to, from, next) => {
  //   console.log(to.path, '---------')
  //   console.log(from.path, '---------*******')
  //   if (to.path === '/flatRoof' && from.path === '/') {
  //     console.log('++++++++++++');
  //     sessionStorage.clear()
  //     location.reload()
  //   }
  // }
}
</script>
<style scoped lang="scss">
@import '@/style/jinlang/home.scss';
@import '@/style/qingtian/home.scss';
</style>
